.company-nav {
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 80px;
  right: 0;
  height: 4rem;
  // @extend .grey, .lighten-1;
  font-weight: 500;
  // @extend .grey-text, .text-darken-4;
  // background-color: #36636e;
  color: black;
}

.company-nav ul {
  display: flex;
}
.select {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 0.5rem;
  color: black;
  margin: 0;
}
.cbx {
  // @extend .select;
  padding-left: 0 !important;
}
.select p,
.cbx p {
  margin: 0;
}
.select {
  padding: 0 1rem 0 0.5rem;
}
.select div select {
  display: inline !important;
  margin: 0 0.5rem;
}
.menu li:nth-child(even) {
  // background-color: #457885;
  // @extend .cyan, .darken-3;
  border-left: solid 1px rgba(0, 0, 0, 0.3);
  border-right: solid 1px rgba(0, 0, 0, 0.3);
}
.cbx p label span::before {
  border: 1px solid black !important;
}
.switch label input[type="checkbox"]:checked + .lever {
  background-color: #6be791;
}
.switch label input[type="checkbox"]:checked + .lever::after {
  background-color: #27c055;
}
.switch label {
  color: black;
}

.get-data {
  margin-left: auto;
  margin-right: 6rem;
  padding: 0 1rem;
}

.loading {
  cursor: default !important;
  // @extend .darken-4;
  display: flex;
}

.ej-avstämt {
  color: red;
}

.avvikelse-belopp-li {
  padding: 0 1rem;
}
.avvikelse-belopp-li div {
  //   bottom: 1rem;
}

// .avvikelse-belopp-li .input-field input[type="text"]:focus + label {
//   color: #333 !important;
// }
// .avvikelse-belopp-li .input-field input:focus {
//   border-bottom: 1px solid #333 !important;
//   box-shadow: 0 1px 0 0 #333 !important;
// }
// .avvikelse-belopp-li .input-field input {
//   border-bottom: 1px solid #333 !important;
//   box-shadow: 0 1px 0 0 #333 !important;
// }
// .avvikelse-belopp-li .input-field input[type="text"] + label {
//   color: #333 !important;
// }
