:root {
  font-size: 16px;
  font-family: "Open Sans";
}

main {
  margin-left: 5rem;
  padding: 1rem;
}

.sfb-navbar {
  width: 5rem;
  height: 100vh;
  position: fixed;
  transition: 200ms ease;
  // @extend .cyan;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.sfb-nav-item {
  width: 100%;
}
.down {
  margin-top: auto;
}

.sfb-nav-item a {
  text-decoration: none;
}

.sfb-nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  // filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);
  color: #424242;

  // @extend .grey-text, .text-darken-4;
}
.sfb-nav-link:hover {
  filter: grayscale(0%) opacity(1);
  color: black;
  background-color: #4dd0e1;
  // @extend .black-text;
  // @extend .cyan, .lighten-2;
}

nav ul a {
  padding: 0 !important;
}

.sfb-nav-link svg {
  font-size: 2em;
  margin-left: 0.75em;
}

.sfb-link-text {
  display: none;
  margin-left: 0.75em;
  font-weight: 500;
}
.sfb-nav-link i {
  font-size: 32px !important;
  min-width: 2rem;
  margin: 0 1.5rem;
}

// /* Small screens */
// @media only screen and (max-width: 600px) {
//    .sfb-navbar {
//      bottom: 0;
//      width: 100vw;
//      height: 5rem;
//    }

//    .sfb-navbar-nav {
//      flex-direction: row;
//    }

//    .sfb-nav-link {
//      justify-content: center;
//    }

//    main {
//      margin: 0;
//    }
//  }

/* Large screens */
// @media only screen and (min-width: 600px) {
.sfb-navbar {
  top: 0;
  width: 5rem;
  height: 100vh;
}

.sfb-navbar:hover {
  width: 16rem;
}

.sfb-navbar:hover .sfb-link-text {
  display: inline;
}
// }
