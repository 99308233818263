.avvikelse-row:not(.selected-row):not(.avviker):hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.avvikelse-row.o:not(.selected-row):not(.avviker):hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.trans-row:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.note-card {
  padding: 0.5rem;
  word-wrap: break-word;
}
.note-name {
  font-size: 0.7rem;
}

.avviker {
  background-color: #bbdefb !important;
  font-weight: 500;
}
.avviker.o {
  background-color: #90caf9 !important;
  font-weight: 500;
}
.avvikelse-row.avviker:hover {
  background-color: #64b5f6 !important;
}
.avvikelse-row.avviker.o:hover {
  background-color: #42a5f5 !important;
}
