.company-card {
  height: 100px;
  font-size: medium;
  font-weight: 600;
  cursor: pointer;
  color: #222;
}
.sad {
  font-size: 3rem;
}
.company-card-logo {
  height: 3rem;
  align-self: center;
}
.company-card > div {
  display: flex;
}
