.card-container {
  max-width: 512px;
  margin: 0 auto;
}

.input-container {
  border: 1px #555 solid;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.input-container p {
  padding: 5px;
  display: flex;
  justify-content: stretch;
}
.input-container p label {
  width: 100%;
  cursor: pointer;
}
.input-container p:first-child {
  border-bottom: 3px double #555;
}
.input-container p:nth-child(even) {
  background-color: #eee;
}
.adduser-header {
  text-align: center;
}
.user-link {
  text-align: center;
}
