.user-row:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.1);
}
.loading-small {
  display: flex;
  justify-content: flex-end;
  align-content: center;
}

.user-card-header {
  padding: 0.5rem 0;
  font-weight: 500;
}

.module-btn {
  margin: 0.5rem;
}
.user-row span {
  padding: 1rem !important;
}
.user-row {
  margin: 0 !important;
}
